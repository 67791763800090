import axios from 'axios'
import {
  getDiscount,
  getCategoryNameByUrl,
  getProductUrl,
  getSearchSpringKeys,
  getCategoryDataByPath,
} from './product-helper'
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'js-cookie'
import { capitalize } from './helpers'
import suggestionDE from '@/public/suggestionsDE.json'
import suggestionIT from '@/public/suggestionsIT.json'
import suggestionFR from '@/public/suggestionsFR.json'
import suggestionES from '@/public/suggestionsES.json'
import suggestionEN from '@/public/suggestionsEN.json'
import { getCurrentRegion } from '@/lib/region-helper'
import { CartClient } from '@/lib/cart-client'
import { createProductSlug } from '@/lib/cache/product-cache'

let ssSessionIdNamespace = Cookies.get('ssSessionIdNamespace') || uuidv4()
let ssUserId = Cookies.get('ssUserId') || uuidv4()
let ssPageLoadIdNamespace = uuidv4()

// if(!ssSessionIdNamespace) {
//   let newId = uuidv4()
//   ssSessionIdNamespace = newId
//   Cookies.set('ssSessionIdNamespace', newId, { expires: 7 })
// }

// if(!ssUserId) {
//   let newId = uuidv4()
//   ssUserId = newId
//   Cookies.set('ssUserId', newId, { expires: 7 })
// }

var config = {
  method: 'get',
  url: getSearchSpringKeys().NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL,
  headers: {
    'searchspring-session-id': ssSessionIdNamespace,
    'searchspring-user-id': ssUserId,
    'searchspring-page-load-id': ssPageLoadIdNamespace,
  },
}

var contentFeedConfig = {
  method: 'get',
  url: getSearchSpringKeys().NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL,
  headers: {
    'searchspring-session-id': ssSessionIdNamespace,
    'searchspring-user-id': ssUserId,
    'searchspring-page-load-id': ssPageLoadIdNamespace,
  },
}

var contentRecsConfig = {
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
  },
}

var suggestionConfig = {
  method: 'get',
  url: `https://suggest-cache.searchspring.net/api/suggest/query?siteId=${
    getSearchSpringKeys().NEXT_PUBLIC_SEARCH_SPRING_SITEID
  }`,
  headers: {
    'Content-Type': 'application/json',
  },
}
var trendingConfig = {
  method: 'post',
  url: getSearchSpringKeys().NEXT_PUBLIC_TRENDING_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  data: {
    siteId: getSearchSpringKeys().NEXT_PUBLIC_SEARCH_SPRING_SITEID,
    limit: 10,
  },
}

const getPageLoadId = () => {
  let lastGtmUrl = Cookies.get('lastGtmUrl')
  let currentPath = window.location.pathname
  if (!lastGtmUrl || lastGtmUrl != currentPath) {
    let newPageLoadId = uuidv4()
    return newPageLoadId
  }
  return ssPageLoadIdNamespace
}

export const getSearchSpringData = async (url) => {
  try {
    let config = {
      method: 'get',
      url: '',
      headers: {
        'searchspring-session-id': ssSessionIdNamespace,
        'searchspring-user-id': ssUserId,
        'searchspring-page-load-id': ssPageLoadIdNamespace,
      },
    }
    let apiUrl = new URL(url)
    if (typeof window != 'undefined') {
      apiUrl.searchParams.append('userId', ssUserId)
      apiUrl.searchParams.append('sessionId', ssSessionIdNamespace)
      apiUrl.searchParams.append('pageLoadId', getPageLoadId())
      apiUrl.searchParams.append('domain', window.location.href)
    }
    config.url = apiUrl.href
    const data = await axios(config)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
    return data
  } catch (error) {
    if (error && error.response && error.response.status) {
      console.error(`SS Error - ${error.response.status}`)
    } else {
      console.error(`SS Error - ${error}`)
    }
  }
}

export const getContentFeedData = async (url = null) => {
  if (url != null) {
    contentFeedConfig.url = url
  }
  let apiUrl = new URL(contentFeedConfig.url)
  if (typeof window != 'undefined') {
    apiUrl.searchParams.append('userId', ssUserId)
    apiUrl.searchParams.append('sessionId', ssSessionIdNamespace)
    apiUrl.searchParams.append('pageLoadId', getPageLoadId())
    apiUrl.searchParams.append('domain', window.location.href)
  }
  contentFeedConfig.url = apiUrl.href
  const data = await axios(contentFeedConfig)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
  return data
}

export const getContentRecsData = async (tag, limit = 8) => {
  let contentUrl = getSearchSpringKeys().NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL
  let urlObject = new URL(contentUrl)
  let siteId = urlObject.searchParams.get('siteId')
  let recsUrl = `https://${siteId}.a.searchspring.io/boost/${siteId}/recommend`
  let recsWithTag = new URL(recsUrl)
  recsWithTag.searchParams.append('tags', tag)
  recsWithTag.searchParams.append('limits', limit)
  if (typeof window != 'undefined') {
    recsWithTag.searchParams.append('userId', ssUserId)
    recsWithTag.searchParams.append('sessionId', ssSessionIdNamespace)
    recsWithTag.searchParams.append('pageLoadId', getPageLoadId())
    recsWithTag.searchParams.append('domain', window.location.href)
  }
  contentRecsConfig.url = recsWithTag.href
  const data = await axios(contentRecsConfig)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
  return data
}

export const getSuggestions = async (params = null) => {
  suggestionConfig.params = params
  let apiUrl = new URL(suggestionConfig.url)
  if (typeof window != 'undefined') {
    apiUrl.searchParams.append('userId', ssUserId)
    apiUrl.searchParams.append('sessionId', ssSessionIdNamespace)
    apiUrl.searchParams.append('pageLoadId', getPageLoadId())
    apiUrl.searchParams.append('domain', window.location.href)
  }
  suggestionConfig.url = apiUrl.href
  const data = await axios(suggestionConfig)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
  return data
}

export const getBackgroundFilter = (params) => {
  // console.log(params)
  if (params.slug.length > 1) {
    let slug = '/'
    let bgFilter = ''
    let count = 0
    params.slug.map((item) => {
      count = count + 1
      slug = slug + item + '/'

      if (bgFilter == '') {
        bgFilter = getCategoryNameByUrl(slug) + '>'
      } else if (params.slug.length == count) {
        bgFilter = bgFilter + getCategoryNameByUrl(slug)
      } else {
        bgFilter = bgFilter + getCategoryNameByUrl(slug) + '>'
      }
    })
    let returnVal = typeof bgFilter == 'string' && bgFilter != '' ? bgFilter.trim() : bgFilter
    return returnVal
  } else if (params.slug.length == 1) {
    return getCategoryNameByUrl(params.slug.join('/'))[0]
  }
  return ''
}

export const generateBGFilter = (path) => {
  const segments = path.split('/')
  let currentPath = ''
  let categoryName = ''

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i]
    const category = getCategoryDataByPath(currentPath + segment)
    if (!category) {
      return null
    }
    currentPath += segment + '/'
    categoryName += category.name + '>'
  }

  return categoryName.slice(0, -1)
}

export const getProductRecommendation = async (url) => {
  let apiUrl = new URL(url)
  if (typeof window != 'undefined') {
    apiUrl.searchParams.append('userId', ssUserId)
    apiUrl.searchParams.append('sessionId', ssSessionIdNamespace)
    apiUrl.searchParams.append('pageLoadId', getPageLoadId())
    apiUrl.searchParams.append('domain', window.location.href)
  }
  const productRecommendation = await axios
    .get(apiUrl.href)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
  return productRecommendation
}

export const getStaticSSuggestions = (language) => {
  // console.log(language)
  let returnlanguage = null
  switch (language) {
    case 'fr':
      returnlanguage = suggestionFR
      break
    case 'it':
      returnlanguage = suggestionIT
      break
    case 'de':
      returnlanguage = suggestionDE
      break
    case 'es':
      returnlanguage = suggestionES
      break
    case 'en':
      returnlanguage = suggestionEN
      break
    default:
      break
  }
  return returnlanguage
}

export const getTrendingSearch = async () => {
  let apiUrl = new URL(trendingConfig.url)
  if (typeof window != 'undefined') {
    apiUrl.searchParams.append('userId', ssUserId)
    apiUrl.searchParams.append('sessionId', ssSessionIdNamespace)
    apiUrl.searchParams.append('pageLoadId', getPageLoadId())
    apiUrl.searchParams.append('domain', window.location.href)
  }
  trendingConfig.url = apiUrl.href
  const productRecommendation = await axios(trendingConfig)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
  return productRecommendation
}

export const getPersonalizedRecommendation = async (
  tag = 'customers-also-viewed',
  sku = null,
  userId = null,
  limit = null
) => {
  const url = new URL(getSearchSpringKeys().NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL)

  url.searchParams.append('tags', tag)

  let lastViewed = Cookies.get('ssViewedProducts')
  if (lastViewed && lastViewed != '') {
    lastViewed = lastViewed.replace('%2C', ',')
    lastViewed = lastViewed.split(',')
    if (lastViewed.includes(sku)) {
      let skuIndex = lastViewed.indexOf(sku)
      lastViewed.splice(skuIndex, 1)
    }
    if (lastViewed && lastViewed?.length > 0) {
      lastViewed = lastViewed.join(',')
      url.searchParams.append('lastViewed', lastViewed)
    }
  }

  if (tag == 'view-cart') {
    let cartProducts = Cookies.get('ssCartProducts')
    if (cartProducts && cartProducts != '') {
      cartProducts = cartProducts.replace('%2C', ',')
      url.searchParams.append('cart', cartProducts)
    }
  }

  if (sku != null) {
    url.searchParams.append('product', sku)
  }

  if (userId != null) {
    url.searchParams.append('shopper', userId)
  }

  if (limit != null) {
    url.searchParams.append('limits', limit)
  }
  if (typeof window != 'undefined') {
    url.searchParams.append('userId', ssUserId)
    url.searchParams.append('sessionId', ssSessionIdNamespace)
    url.searchParams.append('pageLoadId', getPageLoadId())
    url.searchParams.append('domain', window.location.href)
  }
  const { data } = await axios.get(url.href)

  return data[0]
}

export const getProduct3UpRecData = (productRec, baseConfigPath) => {
  const finalData = []
  productRec.map((item) => {
    let salePrice = 0
    let regPrice = 0
    let variantCollection = null
    let categoryName = null
    let imageUrl = 'https://dummyimage.com/600x400/000/fff'
    let noOfVariants = 0
    if (item.thumbnailImageUrl != undefined) {
      imageUrl = item.thumbnailImageUrl
    }
    if (item.ss_variants) {
      variantCollection = JSON.parse(item.ss_variants.replace(/&quot;/g, '"'))
      regPrice = variantCollection[0].price
      noOfVariants = variantCollection.length
    }
    let catName = ''
    if (item.categories) {
      if (item.categories.includes('Shop Men') && item.categories.includes('Shop Women')) {
        catName = ''
      } else if (item.categories.includes('Shop Men')) {
        catName = "Men's"
      } else if (item.categories.includes('Shop Women')) {
        catName = "Women's"
      }
      if (item.categories.includes('Multi Sport')) {
        catName = 'Multi Sport'
      } else {
        for (let cat in item.categories) {
          let val = item.categories[cat].includes('&amp;')
            ? item.categories[cat].replace('&amp;', '&')
            : item.categories[cat]
          let arr = ['Cycling', 'Running', 'Ski']
          if (arr.includes(val)) {
            if (catName == '') {
              catName = val
              break
            } else {
              catName = catName + ' ' + val
              break
            }
          }
        }
      }
    }
    categoryName = catName
    if (item.sale_price) {
      salePrice = item.sale_price
    }
    let proUrl = item.custom_url.slice(0, -1) + '-' + item.sku
    const tempProductData = {
      id: item.id,
      description: item.description,
      custom_fields: 'item.custom_fields',
      categories: item.categories,
      name: item.name,
      salePercentage: getDiscount(regPrice, salePrice),
      onSale: salePrice != '0' ? true : false,
      category: categoryName,
      salePrice: salePrice,
      regularPrice: regPrice,
      variants: noOfVariants,
      variantCollection: variantCollection,
      image: {
        url: item.cdn_standard_url && item.cdn_standard_url[0],
      },
      images: item.cdn_standard_url && item.cdn_standard_url[0],
      custom_url: proUrl,
      weight: item.weight,
      selectedVariant: null,
      sku: item.sku,
    }
    finalData.push(tempProductData)
  })
  return finalData
}

export const getSlugFromSearchSpringFilter = (searchSpringFilter) => {
  let formatedVal = searchSpringFilter.toLowerCase()
  formatedVal = formatedVal.replace(' ', '-')
  formatedVal = formatedVal.replace('>', '/')
  formatedVal = '/' + formatedVal + '/'
  return formatedVal
}

export const getInlineBanners = async (data, allowFilters = false, cachedList = null) => {
  try {
    const checkForFilters = allowFilters || data?.filterSummary?.length == 0

    if (data?.merchandising?.content?.inline?.length > 0 && checkForFilters) {
      let inGridIds = []
      let items = data?.merchandising?.content?.inline
      let country = getCurrentRegion()
      let begin = data?.pagination?.begin
      let end = data?.pagination?.end

      items?.forEach((item) => {
        let itemPartials = item?.value?.split('</script>')
        let position = item?.config?.position?.index || 0

        //check if contentful id exist and postion in pagination range
        if (itemPartials[1] && position + 1 >= begin && position + 1 <= end) {
          let newPosition =
            data?.pagination?.currentPage == 1
              ? position
              : position - data?.pagination?.perPage * data?.pagination.previousPage
          inGridIds.push({
            id: itemPartials[1]?.trim(),
            position: parseInt(newPosition < 0 ? 0 : newPosition),
          })
        }
      })
      const locale =
        country && country.domain == 'en-us'
          ? 'en-US'
          : country.localePrefix == 'en'
          ? 'en-GB'
          : country.localePrefix
      if (inGridIds?.length > 0) {
        let items = []

        if (cachedList && cachedList.length > 0) {
          items = cachedList
        } else {
          const response = await CartClient.post(`/content/plp/inline-banner`, {
            ids: inGridIds?.map((item) => item?.id)?.join(','),
            locale: locale,
          })

          items = response?.success ? response?.items : []
        }

        if (items?.length > 0) {
          let gridItems = []

          items?.forEach((item) => {
            let matchedItem = inGridIds?.find((gridItem) => gridItem?.id == item?.fields.entryTitle)
            let position = matchedItem?.position || 0

            if (matchedItem) {
              gridItems.push({
                gridType: 'banner',
                ...item.fields,
                position,
              })
            }
          })

          if (gridItems?.length > 0) {
            let resultArray = [...data?.results].map((item) => {
              return {
                gridType: 'product',
                ...item,
              }
            })

            gridItems.sort((a, b) => (a.position > b.position ? 1 : -1))

            gridItems?.forEach((item) => {
              if (item?.position == 0) {
                resultArray = [item, ...resultArray]
              } else if (item?.position > resultArray.length) {
                resultArray = [...resultArray, item]
              } else {
                resultArray = [
                  ...resultArray.slice(0, item?.position),
                  item,
                  ...resultArray.slice(item?.position, resultArray?.length),
                ]
              }
            })

            return resultArray
          }
        }
      }
    }

    return data.results
  } catch (error) {
    console.log('getInGridItems error:', error.message)
    return data.results
  }
}

export const getSsProductName = (ssMetafields) => {
  if (ssMetafields?.length > 0) {
    const country = getCurrentRegion()
    const titleMeta = ssMetafields?.find(
      (meta) => meta.key == `${country.localePrefix.toLowerCase()}_full_product_name`
    ).value

    return titleMeta.replace(/[{}]|&quot;/g, '').replaceAll('"', '')
  }

  return ''
}

export const getSsProductSlug = (ssMetafields, sku) => {
  if (ssMetafields?.length > 0) {
    const productName = getSsProductName(ssMetafields)

    if (productName?.length > 0) {
      return createProductSlug(productName, sku)
    }
  }

  return ''
}

export const isFilterActive = (filterName, filterValue, mobileFilters) => {
  try {
    const filterData = `${filterName}--${filterValue}`

    if (mobileFilters != null && Object.values(mobileFilters).indexOf(filterData) > -1) {
      return true
    }
    return false
  } catch (error) {
    console.log('isFilterActive error:', error?.message)
    return false
  }
}

export const getResultCount = (data, excludeBanners = false) => {
  try {
    const totalResults = data?.pagination?.totalResults

    if (excludeBanners) {
      let bannerCount = data?.merchandising?.content?.inline?.length ?? 0

      return totalResults - bannerCount
    }

    let excludedBanners = 0

    if (data?.merchandising?.content?.inline?.length > 0) {
      let banners = data?.merchandising?.content?.inline

      banners?.forEach((item) => {
        let position = item?.config?.position?.index || 0
        if (position > totalResults) {
          excludedBanners++
        }
      })
    }

    return totalResults - excludedBanners

    // get the total results from the pagination

    return data

    console.log('the data', data)
  } catch (error) {
    return data?.pagination?.totalResults
  }
}
